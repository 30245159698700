import Item from "@/components/listings/overview/item"
import { Context } from "@/context/state"
import translate from "@/utils/translation"
import { Fade } from "@mui/material"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useState, useMemo } from "react"
import styled from "styled-components"

const Listings = ({ session, setRegisterModal }) => {
  const router = useRouter()
  const { state, dispatch } = useContext(Context)

  const [fadeIn, setFadeIn] = useState(false)

  // Initialize fadeIn effect
  useEffect(() => {
    setFadeIn(true)
  }, [])

  const SkeletonLoader = ({ length }) =>
    Array.from({ length }).map((_, i) => (
      <div
        className="col-lg-3 col-12 col-sm-6 col-md-4 mt-4 mt-md-2  p-md-2 pb-0 pt-0  skeleton"
        key={i}
      >
        <div className="card">
          <div className="skeleton-image full"></div>
          <div className="card-body">
            <div className="card-title">
              <div className="skeleton-line middle mt-4"></div>
              <div className="d-flex justify-content-between">
                <div className="skeleton-line short"></div>
              </div>
            </div>
            <hr className="m-0 p-0 mb-3" />
            <div className="card-title">
              <div className="d-flex justify-content-between">
                <div className="skeleton-line full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

  return (
    <Wrapper className="d-none d-md-block">
      <div className="container">
        <div className="text-center header">
          <h3>
            {translate("homepage_listings_recent")}
            <span className="highlight">
              {translate("homepage_listings_recent_highlight")}
            </span>
          </h3>
        </div>
        <div className="d-none d-md-flex d-xl-none p-0 m-0 mt-md-4 gap-3">
          {state.listings?.length > 0 ? (
            state.listings
              ?.filter(
                item => item.status === "investing" || item.status === "funded"
              )
              .sort((a, b) => b.status.localeCompare(a.status))
              .slice(0, 3)
              .map((listing, i) => (
                <Fade
                  in={fadeIn}
                  timeout={{ enter: 500, exit: 250 }}
                  style={{ transitionDelay: `${i * 50}ms` }}
                  key={`asi-${i}`}
                >
                  <div className="listing p-0" key={i}>
                    <Item
                      listing={listing}
                      session={session}
                      setRegisterModal={setRegisterModal}
                    />
                  </div>
                </Fade>
              ))
          ) : (
            <SkeletonLoader length={3} />
          )}
        </div>
        <div className="d-none d-xl-flex p-0 m-0 mt-md-4 gap-3">
          {state.listings?.length > 0 ? (
            state.listings
              ?.filter(
                item => item.status === "investing" || item.status === "funded"
              )
              .sort((a, b) => b.status.localeCompare(a.status))
              .slice(0, 4)
              .map((listing, i) => (
                <Fade
                  in={true}
                  timeout={{ enter: 500, exit: 250 }}
                  style={{ transitionDelay: `${i * 50}ms` }}
                  key={`asi-${i}`}
                >
                  <div className="listing p-0" key={i}>
                    <Item
                      listing={listing}
                      session={session}
                      setRegisterModal={setRegisterModal}
                    />
                  </div>
                </Fade>
              ))
          ) : (
            <SkeletonLoader length={4} />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default Listings

const Wrapper = styled.div`
  padding: 5rem 0 3.5rem;

  hr {
    color: #c4bfbf;
  }

  .card-body {
    padding: 0em 1rem 0.5rem;
  }

  .skeleton-image {
    height: 200px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
  }

  .skeleton-line {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .skeleton-button {
    margin-top: 15px;
    height: 35px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 0;
  }

  .full {
    width: 100%;
  }

  .long {
    width: 80%;
  }

  .short {
    width: 30%;
  }

  .middle {
    width: 50%;
  }

  .listing {
    height: 100%;
    width: 100%;
  }

  .header {
    justify-content: center;
    margin: 0 auto;
    max-width: 450px;
    margin-bottom: 2.25rem;
  }

  .highlight {
    color: #ff8500;
  }

  @media (max-width: 800px) {
    .skeleton {
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 768px) {
    .listing {
      margin: 0 auto;
    }

    h2 {
      margin-bottom: 0rem;
      color: #333;
    }
  }
`
