import translate from "@/utils/translation"
import { useRouter } from "next-translate-routes"
import React from "react"
import { CashStack, Houses, HousesFill } from "react-bootstrap-icons"
import CurrencyFormat from "react-currency-format"
import { MdOutlineAutoGraph, MdOutlineConstruction } from "react-icons/md"
import styled from "styled-components"

const Performance = () => {
  return (
    <Wrapper className="mt-4 mt-md-0">
      <div className="line" />
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-3 text-center ">
            <MdOutlineAutoGraph
              size={30}
              className="mb-3 mt-2"
              color="#FB8501"
            />
            <p className="amount">
              {translate("homepage_performance_return")}%
            </p>
            <p> {translate("homepage_performance_return_title")}</p>
          </div>
          <div className="col-6 col-md-3 text-center">
            <CashStack size={30} className="mb-3 mt-2" color="#FB8501" />
            <p className="amount d-none d-md-block">
              €{translate("homepage_performance_invested")}
            </p>
            <p className="amount d-md-none">€4.6M+</p>
            <p>{translate("homepage_performance_invested_title")}</p>
          </div>
          <div className="col-6 col-md-3 mt-md-0 mt-5 text-center">
            <MdOutlineConstruction
              size={30}
              className="mb-3"
              style={{ marginTop: "0.5rem" }}
              color="#FB8501"
            />
            <p className="amount">
              {translate("homepage_performance_under_construction")}
            </p>
            <p>{translate("homepage_performance_under_construction_title")}</p>
          </div>
          <div className="col-6 col-md-3 mt-md-0 mt-5 text-center">
            <HousesFill size={30} className="mb-3 mt-2" color="#FB8501" />
            <p className="amount">
              {translate("homepage_performance_pipeline")}
            </p>
            <p>{translate("homepage_performance_pipeline_title")}</p>
          </div>
        </div>
      </div>
      <div className="line mt-4 mb-0" />
    </Wrapper>
  )
}

export default Performance

const Wrapper = styled.div`
  padding: 0rem 0 0;
  margin-top: 1rem;
  overflow: auto;
  background: #fbfaf8;

  .line {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin-bottom: 1.25rem;

    //fade left and right
    background: linear-gradient(to right, transparent, #f0f0f0, transparent);
  }

  p {
    color: #333;
    margin: 0;
    font-weight: 300;
    font-size: 0.875rem;
  }

  .amount {
    font-size: 1.25rem;
    font-weight: 500;
    color: rgb(23, 35, 59) !important;
  }

  @media (max-width: 768px) {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }

  position: relative;
`
