import translate from "@/utils/translation"
import { Slider } from "@mui/material"
import React from "react"
import { Dot } from "react-bootstrap-icons"
import { FaCheck } from "react-icons/fa"
import styled, { keyframes } from "styled-components"

const SplitBar = ({ listing }) => {
  const sliderValue =
    listing.status === "funded" ? 100 : listing?.financials?.fundPercent || 0
  const investors = listing?.financials?.investors || 0

  return (
    <>
      <Bar className="bar">
        <div className="d-flex mt-2 mb-2">
          <Slider className="slider" value={parseFloat(sliderValue)} />
        </div>
        <div className="row">
          {sliderValue === 100 && investors ? (
            <div className="fundPercent d-flex col-12">
              <p>
                <div>
                  <FaCheck
                    color="#39CF83"
                    className="me-1"
                    style={{ marginTop: "-2px" }}
                    size="12"
                  />
                  <span className="bold">
                    {" " + investors}{" "}
                    {translate("listing_single_summarybox_investors") + " "}
                  </span>
                  {translate("listing_single_summarybox_investors_funded")}
                </div>
              </p>
            </div>
          ) : (
            <>
              <div className="fundPercent d-flex col-12 pe-0">
                <p>
                  {listing.status === "investing" && <span className="dot" />}
                  <span className="bold">
                    {" " + sliderValue?.toFixed(0)}%{" "}
                  </span>
                  {translate("listing_single_summarybox_financed")}
                  {investors > 0 && (
                    <>
                      ,<span className="bold">{" " + investors} </span>
                      {translate("listing_single_summarybox_investors")}
                    </>
                  )}
                </p>
              </div>
            </>
          )}
        </div>
      </Bar>
    </>
  )
}

export default SplitBar

// Define the keyframes for pulsing
const pulse = keyframes`
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
  }
`

const Bar = styled.div`
  width: 100%;

  .slider {
    padding: 0 0 0.2rem 0;
    height: 8px;
    color: #39cf83;
  }

  .dot {
    width: 8px;
    height: 8px;
    background-color: #39cf83;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    /* Add pulsing animation */
    animation: ${pulse} 2.5s infinite;
  }

  .MuiSlider-thumb {
    display: none;
    cursor: none;

    :hover,
    .Mui-active {
      display: hidden;
      cursor: none;
    }
  }

  .fundPercent {
    font-size: 0.7rem;
    font-weight: 400;
    color: #737780;
  }

  .bold {
    font-weight: 600;
  }

  .funded {
    width: 100%;
    height: 10px;
    background-color: rgb(251, 133, 0);
    border-left: 3px solid white;

    :first-child {
      border-left: none;
      border-radius: 10px 0 0 10px;
    }

    :last-child {
      border-radius: 0px 10px 10px 0;
    }
  }

  .remaining {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-left: 3px solid white;

    :last-child {
      border-left: none;
      border-radius: 0px 10px 10px 0;
    }
  }

  p {
    margin: 0;
  }
`
