import { useSession } from "next-auth/react"
import { useRouter } from "next-translate-routes"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios" // Ensure axios is installed: npm install axios

const AFMBanner = () => {
  const { data: session } = useSession()
  const router = useRouter()
  const [isNetherlands, setIsNetherlands] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Function to fetch user's geolocation
    const fetchGeolocation = async () => {
      try {
        const response = await axios.get("https://get.geojs.io/v1/ip/geo.json")
        const countryCode = response.data.country_code

        if (countryCode === "NL") {
          setIsNetherlands(true)
        }
      } catch (error) {
        console.error("Geolocation error:", error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchGeolocation()
  }, [])

  if (isLoading) {
    // Optionally, return a loader or nothing while determining location
    return null
  }

  if (!isNetherlands) {
    // Do not render the banner if the user is not in the Netherlands
    return null
  }

  return (
    <Wrapper className="w-100">
      <div className="text-center">
        <img
          src="/components/AFM/AFM-Banner.png"
          className="w-100"
          alt="AFM Banner"
        />
      </div>
    </Wrapper>
  )
}

export default AFMBanner

const Wrapper = styled.div`
  .small {
    font-size: 11px;
    color: #666;
    margin-bottom: 0.5rem;
  }
`
