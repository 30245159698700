import styled from "styled-components"
import translate from "@/utils/translation"
import SplitBar from "@/components/common/Listing/splitBar/SplitBar"
import TagManager from "react-gtm-module"
import { useRouter } from "next-translate-routes"
import { useSession } from "next-auth/react"
import { Chip } from "@mui/material"

const Item = ({ listing, setRegisterModal }) => {
  const router = useRouter()
  const { data: session } = useSession()

  return (
    <Wrapper
      onClick={() => {
        if (
          session &&
          (listing.status === "investing" || listing.status === "funded")
        ) {
          TagManager.dataLayer({
            dataLayer: {
              event: "listings_button_single",
              user_id: session?.id,
              slug: listing?.slug,
              uid: listing?.uid,
            },
          })
          router.push(`/listings/${listing?.slug}`)
        } else if (setRegisterModal) {
          setRegisterModal(true)
        }
      }}
      className="listing-item"
      style={{
        cursor:
          listing?.status === "investing" || listing?.status === "funded"
            ? "pointer"
            : "default",
      }}
    >
      <div
        className="banner"
        style={{
          backgroundImage: `url(${listing?.images?.banner?.formats?.small?.url})`,
        }}
      >
        {listing.status === "in development" ? (
          <Chip
            label={<div>{translate("listing_single_status_closed")}</div>}
            className="status"
          />
        ) : (
          listing.status === "investing" && (
            <Chip
              label={<div>{translate("listing_single_status_open")}</div>}
              className="status open"
            />
          )
        )}
      </div>
      <div>
        <div className="pt-3 pe-3 ps-3 pb-3">
          <div className="d-flex mb-0 location justify-content-between">
            <div className="street mt-0">
              {translate("listing_title_project")}
              {listing?.name}
            </div>
          </div>
          {listing.details.type === "holiday" ? (
            <div className="project-type">
              {translate("listing_single_item_holiday")}
            </div>
          ) : listing?.details?.type === "residential" ? (
            <div className="project-type">
              {translate("listing_single_item_rent")}
            </div>
          ) : null}
          {listing.status === "investing" || listing.status === "funded" ? (
            <div className="splitProcess">
              <SplitBar listing={listing} />
            </div>
          ) : null}
        </div>
      </div>
    </Wrapper>
  )
}

export default Item

/* Wrapper component */
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  transition: box-shadow 200ms;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;

  :hover {
    .invest_button {
      background: #fb8501;
    }
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .highlight {
    font-weight: 600;
  }

  .status {
    position: absolute;
    background: #fff0e0;
    top: 10px;
    font-weight: 400;
    font-size: 0.75rem;
    left: 10px;

    .split {
      padding: 0.2rem 0.4rem 0.2rem 0.2em;
    }
  }

  .open {
    background: #3ccf83;
    font-weight: 500;
    color: white;
    font-size: 0.8rem;
  }

  /* Banner styles */
  .banner,
  .split-banner {
    width: 100%;
    height: 210px;
    background-size: cover;
    border-radius: 5px 5px 0 0;
  }

  .project-type {
    color: #737780;
    font-size: 0.8rem;
    margin-top: 0.1rem;
  }

  /* KPI styles */

  .subtitle {
    color: #737780;
  }

  hr {
    color: #c4bfbf;
    margin: 0.8rem;
  }

  /* Bookmark styles */
  .bookmark {
    position: absolute;
    right: 10px;
    top: 160px;

    .heart {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 0.2rem 0.3rem 0.22rem;
      border-radius: 20%;

      svg {
        margin-bottom: 1px;
      }

      :hover {
        background-color: #fff3e5;
        svg {
          fill: #fb8500;
        }
      }
    }

    .active {
      background-color: #fff3e5;
    }
  }

  .price {
    font-size: 1rem;
    font-weight: 700;
    color: #fb8501;
  }

  /* Street styles */
  .street {
    color: #17233b;
    font-weight: 600;
    padding-right: 0.5rem;
    margin: auto 0;
    font-size: 0.875rem;
  }

  /* Price styles */
  .price {
    font-weight: 600;
  }

  .subPrice {
    font-size: 0.8rem;
    font-weight: 400;
    color: #737780;
    padding: 0.2rem 0 0 0.5rem;
  }

  /* Details styles */
  .details {
    font-size: 0.8rem;
    color: #454f62;

    padding: 0 0.2rem;

    div {
    }

    .item {
      margin-left: 0.5rem;

      :first-child {
        margin-left: 0;
      }
    }

    svg {
      margin: 0 5px 0 10px;

      :first-child {
        margin: 0 5px 0 0;
      }
    }
  }

  /* Divider styles */
  .divider {
    padding: 0;
    color: #ebebeb;
    font-size: 0.7rem;
    margin-top: 0.1rem;
  }

  .extra-interest {
    color: #1b9d46;
  }

  .invest_button {
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 5px;
    background: #fb8501d1;
  }

  /* City styles */
  .city {
    position: absolute;
    top: 150px;
    left: 5px;
    z-index: 20;
    color: white;
    border-radius: 5px;
    padding: 0.2rem 0.4rem;
    text-align: center;

    .MuiChip-root {
      border-radius: 5px;
    }
  }

  .spacer {
    padding: 0.5rem;
  }
`
