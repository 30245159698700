import translate from "@/utils/translation"
import { Avatar } from "@mui/material"
import React from "react"
import { Linkedin } from "react-bootstrap-icons"
import styled from "styled-components"

const Duediligence = () => {
  return (
    <Wrapper className="container ps-4 pe-4 ps-md-3 pe-md-3">
      <div className="row ps-1 pe-1 ps-md-0 pe-md-0">
        <div className="col-12 ps-2 pe-2">
          {/* <h3>{translate("homepage_sourcing_title")}</h3>
          <div className="d-flex stepper p-0">
            <div className="item">
              <h4>{translate("homepage_sourcing_step_1_title")} +/-</h4>
              <p>{translate("homepage_sourcing_step_1_description")}</p>
            </div>
            <div className="item second">
              <h4>{translate("homepage_sourcing_step_2_title")}</h4>
              <p>{translate("homepage_sourcing_step_2_description")}</p>
            </div>
            <div className="item third">
              <h4>{translate("homepage_sourcing_step_3_title")}</h4>
              <p>{translate("homepage_sourcing_step_3_description")}</p>
            </div>
            <div className="item fourth">
              <h4>
                {"< "}
                {translate("homepage_sourcing_step_4_title")}
              </h4>
              <p>{translate("homepage_sourcing_step_4_description")}</p>
            </div>
          </div> */}
          <div className=" d-flex notition">
            <Avatar
              src="/pages/about/employees/tom-2.jpg"
              alt="employee"
              className="ms-auto me-auto"
              sx={{ width: 80, height: 80 }}
            />
            <div className="ms-3 ms-md-4">
              <p>{translate("homepage_sourcing_quote")}</p>
              <div className="d-flex name">
                <h5>Tom van der Laan</h5>
                <p>, Co-founder</p>
                <a
                  className="ms-2 clickable"
                  style={{ marginTop: "-0.15rem", color: "black" }}
                  href="https://www.linkedin.com/in/tvanderlaan/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Duediligence
const Wrapper = styled.div`
  padding: 0rem 0 6rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem; /* Fixed missing value */
    line-height: 1.75;
  }

  .stepper {
    margin-top: 2rem;
    display: flex; /* Ensure proper alignment */

    .item {
      width: 25%;
      background: white;
      padding: 1.75rem 2rem 1rem;
      border-right: 0px;
      position: relative;

      p {
        color: #767676;
        font-size: 0.9rem;
        font-weight: 400;
      }

      h4 {
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 0rem;
      }

      :last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50px;
        left: calc(100% - 8px); /* Adjust for smoother positioning */
        transform: rotate(45deg);
        background-color: white !important;
        border-radius: 2px;
        z-index: 2;
      }

      &:last-child:after {
        display: none !important;
      }

      @media (max-width: 768px) {
        padding: 1.25rem 1.5rem 0.75rem;
        width: 100%;

        :first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        :last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 0px;
        }
      }
    }

    .second {
      background: #dff2e9 !important;

      &:after {
        background-color: #dff2e9 !important;
      }
    }

    .third {
      background: #bbe6d3 !important;
      border-right: 0px;

      &:after {
        background-color: #bbe6d3 !important;
      }
    }

    .fourth {
      background: #89d5b3 !important;

      &:after {
        background-color: #89d5b3 !important;
      }
    }
  }

  .notition {
    max-width: 600px;
    margin: 0 auto;

    p {
      font-size: 1rem;
      font-weight: 300;
      color: rgb(23, 35, 59);
      margin-bottom: 0.5rem;
      font-style: italic;
    }

    h5 {
      font-size: 1rem;
      font-weight: 600;
    }

    .name {
      font-size: 1rem;
      font-weight: 500;

      svg {
        margin-top: -0.15rem;
      }

      p {
        font-style: normal;
        font-weight: 400;
        margin-top: -1px;
        font-size: 0.875rem;
        color: #17233b;
      }
    }

    @media (max-width: 768px) {
      h3 {
        font-size: 1.125rem;
      }

      p {
        margin-top: 0.5rem;
        font-size: 0.875rem;
      }
      .name {
        p {
          line-height: 1.3;
          margin: 0 !important;
        }
      }
    }

    @media (max-width: 500px) {
      p {
        margin-top: 0rem !important;
        font-size: 0.875rem;
      }

      .name {
        h5 {
          font-size: 0.9rem;
        }

        p {
          margin: 0 !important;
          font-size: 0.85rem !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    h3 {
      line-height: 1.5;
    }

    padding: 0rem 0 2rem;

    .stepper {
      padding: 0rem 3rem;
      flex-direction: column;
      border-radius: 8px;
      text-align: center;

      .item {
        &:not(:last-child) {
        }

        :after {
          border-right: 1px solid #dedede !important;
          border-right: 1px solid #dedede !important;
          border-top: 0px solid #dedede !important;
        }

        &:not(:last-child):after {
          top: 93%;
          right: auto;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`
